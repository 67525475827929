<template>
  <div class="pkTournamentPro" :class="client == 'Android' ? 'androidBox' : ''">
     <div class="pkBanner">
     </div>
     <div class="pkRules" v-if="tabOneFlag == 1 || tabOneFlag == 2">
        <div class="top3GiftBox" >
          <p>
            <span>{{ $t("pkTop2") }}</span>
            <span>{{ $t("pk_es_10day") }}</span>
            <span>+</span>
            <span>{{ $t("pk_es_host_top2") }}</span>
          </p>
          <p>

            <span>{{ $t("pkTop1") }}</span>
            <span>{{ $t("pk_es_15day") }}</span>
            <span>+</span>
            <span>{{ $t("pk_es_host_top1") }}</span>
          </p>
          <p>
            <span>{{ $t("pkTop3") }}</span>
            <span>{{ $t("pk_es_5day") }}</span>
            <span>+</span>
            <span>{{ $t("pk_es_host_top3") }}</span>
          </p>
        </div>
        <div class="rulesMsgBox">
          <div class="rulesMsg">
            <p>{{ $t("pk_es_rule1")  }} </p>
            <p>{{ $t("pk_es_rule2")  }} </p>
            <p>{{ $t("pk_es_rule3")  }} </p>
            <!-- <div>{{$t("pk_es_rule3")}}</div> -->
          </div>
        </div>
      </div>
      <!-- 用户奖励和规则 -->
     <div class="pkRules userPkRules" v-if="tabOneFlag == 3">
        <div class="top3GiftBox" >
          <p>
            <span>{{ $t("pkTop2") }}</span>
            <span>{{ $t("pk_es_10day") }}</span>
            <span>+</span>
            <span>{{ $t("pk_es_supporter_top2") }}</span>
          </p>
          <p>
            <span>{{ $t("pkTop1") }}</span>
            <span>{{ $t("pk_es_15day") }}</span>
            <span>+</span>
            <span>{{ $t("pk_es_supporter_top1") }}</span>
          </p>
          <p>
            <span>{{ $t("pkTop3") }}</span>
            <span>{{ $t("pk_es_5day") }}</span>
            <span>+</span>
            <span>{{ $t("pk_es_supporter_top3") }}</span></p>
        </div>
        <div class="rulesMsgBox">
          <div class="rulesMsg">
            <p>{{ $t("pk_es_rule1")  }} </p>
            <p>{{ $t("pk_es_rule2")  }} </p>
            <p>{{ $t("pk_es_rule3")  }} </p>
          </div>
        </div>
      </div>
        <!-- vote入口 -->
      <div class="voteBox" v-if="voteView == 1">
        <header>{{ $t('pk_vote_title') }}</header>
        <p>{{ $t('pk_vote_body') }}</p>
        <p @click="changeVotePop(1)">{{ $t('pk_vote_buttom') }}</p>
      </div>
     <div class="pkTabBox">
      <div class="tabBtnBoxParent">
          <div class="tabBtnBox" :class="tabOneFlag == 2 ? 'tabBtnBox2' : ''">
            <ul class="oneTab">
              <li :class="tabOneFlag == 1 ? 'activeOne' : ''" @click="changeTabOneFlag(1)">
                <p>{{$t("pkTourament")}}</p>
                <span></span>
              </li>
              <li :class="tabOneFlag == 2 ? 'activeOne' : ''" @click="changeTabOneFlag(2)">
                <p>{{$t("pkToday")}}</p>
                <span></span>
              </li>
              <li :class="tabOneFlag == 3 ? 'activeOne' : ''" @click="changeTabOneFlag(3)">
                <p>{{$t("pkSupporter")}}</p>
                <span></span>
              </li>
            </ul>
            <ul class="twoTab" v-if="tabOneFlag == 1">
              <li :class="tabTwoFlag == 1 ? 'activeTwo' : ''" @click="changeTabTwoFlag(1)">{{$t("pkQualifying")}}</li>
              <li :class="tabTwoFlag == 2 ? 'activeTwo' : ''" @click="changeTabTwoFlag(2)">{{$t("pkPromotion")}}</li>
              <li :class="tabTwoFlag == 3 ? 'activeTwo' : ''" @click="changeTabTwoFlag(3)">{{$t("pkSummit")}}</li>
            </ul>
          </div>
        </div>
        <ul class="twoTabContent" v-if="tabOneFlag == 1">
          <li v-if="tabTwoFlag == 1" >
            <p class="pkInfoTitle"> 
              <span class="titleLeftIcon"></span>
              <span class="titileText">{{$t("pk16Finals")}} </span>
              <span class="titleRightIcon"></span>
            </p>
             <ul class="pkUserItem">
              <li v-for="(item,index) in tab1PkList" :key="index">
                <div class="leftUserInfo" v-if="item.leftUserInfo">
                  <div class="userInfoLeft">
                    <div class="userInfoTop">
                      <span class="winBox">
                        <img @click="skipUserInfo(item.leftUserInfo.uid1)" v-if="item.leftUserInfo.winId != 0 && item.leftUserInfo.winId == item.leftUserInfo.uid1" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox" @click="skipUserInfo(item.leftUserInfo.uid1)">
                        <img class="toukuang" v-if="item.leftUserInfo.pictureFrameDetailMinUrl1" :src="item.leftUserInfo.pictureFrameDetailMinUrl1" alt="">
                        <img v-if="item.leftUserInfo.headIcon1" class="winIcon" :src="item.leftUserInfo.headIcon1" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.leftUserInfo.name1 }}</span>
                    </div>
                    <div>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.leftUserInfo.uid2)" v-if="item.leftUserInfo.winId != 0 && item.leftUserInfo.winId == item.leftUserInfo.uid2" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox" @click="skipUserInfo(item.leftUserInfo.uid2)">
                        <img class="toukuang" v-if="item.leftUserInfo.pictureFrameDetailMinUrl2" :src="item.leftUserInfo.pictureFrameDetailMinUrl2" alt="">
                        <img  v-if="item.leftUserInfo.headIcon2" class="winIcon" :src="item.leftUserInfo.headIcon2" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.leftUserInfo.name2 }}</span>
                    </div>
                  </div>
                  <div class="userInfoCenter">
                    <span :class="item.leftUserInfo.winId != 0 && item.leftUserInfo.winId == item.leftUserInfo.uid1 ? 'actTopLeftLine' : ''"></span>
                    <!-- <span>{{ countJson[index][0] }}</span> -->
                    <span></span>
                    <span :class="item.leftUserInfo.winId != 0 && item.leftUserInfo.winId == item.leftUserInfo.uid2 ? 'actBottomLeftLine' : ''"></span>
                  </div>
                  <div class="userInfoRight">
                    <span>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.leftUserInfo.winId)" v-if="item.leftUserInfo.winId != 0" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <img @click="skipUserInfo(item.leftUserInfo.uid1)" class="toukuang" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid1 && item.leftUserInfo.pictureFrameDetailMinUrl1" :src="item.leftUserInfo.pictureFrameDetailMinUrl1" alt="">
                      <img @click="skipUserInfo(item.leftUserInfo.uid2)" class="toukuang" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid2 && item.leftUserInfo.pictureFrameDetailMinUrl2" :src="item.leftUserInfo.pictureFrameDetailMinUrl2" alt="">
                      <img @click="skipUserInfo(item.leftUserInfo.uid2)" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid2 && item.leftUserInfo.headIcon2" :src="item.leftUserInfo.headIcon2" alt="">
                      <img @click="skipUserInfo(item.leftUserInfo.uid1)" v-else-if="item.leftUserInfo.winId == item.leftUserInfo.uid1 && item.leftUserInfo.headIcon1" :src="item.leftUserInfo.headIcon1" alt="">
                      <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      <span class="userName" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid1">{{ item.leftUserInfo.name1}}</span>
                      <span class="userName" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid2">{{ item.leftUserInfo.name2}}</span>
                    </span>
                  </div>
                </div>
                <div class="rightUserInfo" v-if="item.rightUserInfo">
                  <div class="userInfoRight">
                    <span>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.rightUserInfo.winId)" v-if="item.rightUserInfo.winId != 0" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <img @click="skipUserInfo(item.rightUserInfo.uid1)" class="toukuang" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid1 && item.rightUserInfo.pictureFrameDetailMinUrl1" :src="item.rightUserInfo.pictureFrameDetailMinUrl1" alt="">
                      <img @click="skipUserInfo(item.rightUserInfo.uid2)" class="toukuang" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid2 && item.rightUserInfo.pictureFrameDetailMinUrl2" :src="item.rightUserInfo.pictureFrameDetailMinUrl2" alt="">
                      <img @click="skipUserInfo(item.rightUserInfo.uid2)" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid2 && item.rightUserInfo.headIcon2" :src="item.rightUserInfo.headIcon2" alt="">
                      <img @click="skipUserInfo(item.rightUserInfo.uid1)" v-else-if="item.rightUserInfo.winId == item.rightUserInfo.uid1 && item.rightUserInfo.headIcon1" :src="item.rightUserInfo.headIcon1" alt="">
                      <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      <span class="userName" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid1">{{ item.rightUserInfo.name1}}</span>
                      <span class="userName" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid2">{{ item.rightUserInfo.name2}}</span>
                    </span>
                  </div>
                  <div class="userInfoCenter">
                    <span :class="item.rightUserInfo.winId != 0 &&  item.rightUserInfo.winId == item.rightUserInfo.uid1 ? 'actTopRightLine' : ''"></span>
                    <!-- <span>{{ countJson[index][1] }}</span> -->
                    <span></span>
                    <span :class="item.rightUserInfo.winId != 0 && item.rightUserInfo.winId == item.rightUserInfo.uid2 ? 'actBottomRightLine' : ''"></span>
                  </div>
                  <div class="userInfoLeft">
                    <div class="userInfoTop">
                      <span class="winBox">
                        <img @click="skipUserInfo(item.rightUserInfo.uid1)" v-if="item.rightUserInfo.winId != 0 && item.rightUserInfo.winId == item.rightUserInfo.uid1" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox" @click="skipUserInfo(item.rightUserInfo.uid1)">
                      <img class="toukuang" v-if="item.rightUserInfo.pictureFrameDetailMinUrl1" :src="item.rightUserInfo.pictureFrameDetailMinUrl1" alt="">
                        <img v-if="item.rightUserInfo.headIcon1" class="winIcon" :src="item.rightUserInfo.headIcon1" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.rightUserInfo.name1 }}</span>
                    </div>
                    <div>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.rightUserInfo.uid2)" v-if="item.rightUserInfo.winId != 0 && item.rightUserInfo.winId == item.rightUserInfo.uid2" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox" @click="skipUserInfo(item.rightUserInfo.uid2)">
                        <img class="toukuang" v-if="item.rightUserInfo.pictureFrameDetailMinUrl2" :src="item.rightUserInfo.pictureFrameDetailMinUrl2" alt="">
                        <img  v-if="item.rightUserInfo.headIcon2" class="winIcon" :src="item.rightUserInfo.headIcon2" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.rightUserInfo.name2 }}</span>
                    </div>
                  </div>
                </div>
              </li>
             </ul>
          </li>
          <li v-if="tabTwoFlag == 2">
            <p class="pkInfoTitle"> 
              <span class="titleLeftIcon"></span>
              <span class="titileText">{{$t("pk8Finals")}} </span>
              <span class="titleRightIcon"></span>
            </p>
            <ul class="pkUserItem">
              <li v-for="(item,index) in tab2PkList" :key="index">
                <div class="leftUserInfo">
                  <div class="userInfoLeft">
                    <div class="userInfoTop">
                      <span class="winBox">
                        <img @click="skipUserInfo(item.leftUserInfo.uid1)" v-if="item.leftUserInfo.winId != 0 && item.leftUserInfo.winId == item.leftUserInfo.uid1" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox" @click="skipUserInfo(item.leftUserInfo.uid1)">
                        <img class="toukuang" v-if="item.leftUserInfo.pictureFrameDetailMinUrl1" :src="item.leftUserInfo.pictureFrameDetailMinUrl1" alt="">
                        <img  v-if="item.leftUserInfo.headIcon1" class="winIcon" :src="item.leftUserInfo.headIcon1" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.leftUserInfo.name1 }}</span>
                    </div>
                    <div>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.leftUserInfo.uid2)" v-if="item.leftUserInfo.winId != 0 && item.leftUserInfo.winId == item.leftUserInfo.uid2" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox" @click="skipUserInfo(item.leftUserInfo.uid2)">
                        <img class="toukuang" v-if="item.leftUserInfo.pictureFrameDetailMinUrl2" :src="item.leftUserInfo.pictureFrameDetailMinUrl2" alt="">
                        <img  v-if="item.leftUserInfo.headIcon2" class="winIcon" :src="item.leftUserInfo.headIcon2" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.leftUserInfo.name2 }}</span>
                    </div>
                  </div>
                  <div class="userInfoCenter">
                    <span :class="item.leftUserInfo.winId == item.leftUserInfo.uid1 ? 'actTopLeftLine' : ''"></span>
                    <!-- <span>{{ countJson[index][0] }}</span> -->
                    <span></span>
                    <span :class="item.leftUserInfo.winId == item.leftUserInfo.uid2 ? 'actBottomLeftLine' : ''"></span>
                  </div>
                  <div class="userInfoRight">
                    <span>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.leftUserInfo.winId)" v-if="item.leftUserInfo.winId != 0" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                        <img @click="skipUserInfo(item.leftUserInfo.uid2)" class="toukuang" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid2 && item.leftUserInfo.pictureFrameDetailMinUrl2" :src="item.leftUserInfo.pictureFrameDetailMinUrl2" alt="">
                        <img @click="skipUserInfo(item.leftUserInfo.uid1)" class="toukuang" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid1 && item.leftUserInfo.pictureFrameDetailMinUrl1" :src="item.leftUserInfo.pictureFrameDetailMinUrl1" alt="">
                      <img @click="skipUserInfo(item.leftUserInfo.uid2)" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid2 && item.leftUserInfo.headIcon2" :src="item.leftUserInfo.headIcon2" alt="">
                      <img @click="skipUserInfo(item.leftUserInfo.uid1)" v-else-if="item.leftUserInfo.winId == item.leftUserInfo.uid1 && item.leftUserInfo.headIcon1" :src="item.leftUserInfo.headIcon1" alt="">
                      <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      <span class="userName" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid1">{{ item.leftUserInfo.name1}}</span>
                      <span class="userName" v-if="item.leftUserInfo.winId == item.leftUserInfo.uid2">{{ item.leftUserInfo.name2}}</span>
                    </span>
                  </div>
                </div>
                <div class="rightUserInfo">
                  <div class="userInfoRight">
                    <span>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.rightUserInfo.winId)" v-if="item.rightUserInfo.winId != 0" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                        <img @click="skipUserInfo(item.rightUserInfo.uid2)" class="toukuang" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid2 && item.rightUserInfo.pictureFrameDetailMinUrl2" :src="item.rightUserInfo.pictureFrameDetailMinUrl2" alt="">
                        <img @click="skipUserInfo(item.rightUserInfo.uid1)" class="toukuang" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid1 && item.rightUserInfo.pictureFrameDetailMinUrl1" :src="item.rightUserInfo.pictureFrameDetailMinUrl1" alt="">
                      <img @click="skipUserInfo(item.rightUserInfo.uid2)" v-if="item.rightUserInfo.winId == item.rightUserInfo.uid2 && item.rightUserInfo.headIcon2" :src="item.rightUserInfo.headIcon2" alt="">
                      <img @click="skipUserInfo(item.rightUserInfo.uid1)" v-else-if="item.rightUserInfo.winId == item.rightUserInfo.uid1 && item.rightUserInfo.headIcon1" :src="item.rightUserInfo.headIcon1" alt="">
                      <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      <span class="userName" v-if="item.rightUserInfo.winId == item.leftUserInfo.uid1">{{ item.rightUserInfo.name1}}</span>
                      <span class="userName" v-if="item.rightUserInfo.winId == item.leftUserInfo.uid2">{{ item.rightUserInfo.name2}}</span>
                    </span>
                  </div>
                  <div class="userInfoCenter">
                    <span :class="item.rightUserInfo.winId == item.rightUserInfo.uid1 ? 'actTopRightLine' : ''"></span>
                    <!-- <span>{{ countJson[index][1] }}</span> -->
                    <span></span>
                    <span :class="item.rightUserInfo.winId == item.rightUserInfo.uid2 ? 'actBottomRightLine' : ''"></span>
                  </div>
                  <div class="userInfoLeft">
                    <div class="userInfoTop">
                      <span class="winBox">
                        <img  @click="skipUserInfo(item.rightUserInfo.uid1)" v-if="item.rightUserInfo.winId != 0 &&  item.rightUserInfo.winId == item.rightUserInfo.uid1" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox"  @click="skipUserInfo(item.rightUserInfo.uid1)">
                        <img class="toukuang" v-if="item.rightUserInfo.pictureFrameDetailMinUrl1" :src="item.rightUserInfo.pictureFrameDetailMinUrl1" alt="">
                        <img v-if="item.rightUserInfo.headIcon1" class="winIcon" :src="item.rightUserInfo.headIcon1" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.rightUserInfo.name1 }}</span>
                    </div>
                    <div>
                      <span class="winBox">
                        <img @click="skipUserInfo(item.rightUserInfo.uid2)" v-if="item.rightUserInfo.winId != 0 && item.rightUserInfo.winId == item.rightUserInfo.uid2" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox" @click="skipUserInfo(item.rightUserInfo.uid2)">
                        <img class="toukuang" v-if="item.rightUserInfo.pictureFrameDetailMinUrl2" :src="item.rightUserInfo.pictureFrameDetailMinUrl2" alt="">
                        <img  v-if="item.rightUserInfo.headIcon2" class="winIcon" :src="item.rightUserInfo.headIcon2" alt="">
                        <img v-else class="winIcon nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                      <span class="userName">{{ item.rightUserInfo.name2 }}</span>
                    </div>
                  </div>
                </div>
              </li>
             </ul>
          </li>
          <li v-if="tabTwoFlag == 3" class="lastRankBox">
            <!-- 左侧用户信息 1,2名 -->
            <div class="lastLeftRank">
                <header>{{$t("pkQuater")}}</header>
                <div class="userInfoLeftBox">
                  <div class="userInfoLeftTop">
                    <div class="userInfoLeft">
                      <div class="userInfoTop">
                        <span class="winBox" >
                          <img class="winIcon" @click="skipUserInfo(tab3PkList[0].uid1)"  v-if="tab3PkList[0] && tab3PkList[0].winId != 0 && tab3PkList[0].winId == tab3PkList[0].uid1" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                        </span>
                        <span class="pictureBox">
                          <img @click="skipUserInfo(tab3PkList[0].uid1)" class="toukuang" v-if="tab3PkList[0] && tab3PkList[0].pictureFrameDetailMinUrl1" :src="tab3PkList[0].pictureFrameDetailMinUrl1" alt="">
                          <img @click="skipUserInfo(tab3PkList[0].uid1)" v-if="tab3PkList[0] && tab3PkList[0].headIcon1" :src="tab3PkList[0].headIcon1" alt="">
                          <template v-else>
                              <img v-if="!tab3PkList[0].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                              <img @click="skipUserInfo(tab3PkList[0].uid1)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                          </template>
                        </span>
                        <span class="userName" v-if="tab3PkList[0]">{{ tab3PkList[0].name1 }}</span>
                        <span v-else class="userName"></span>
                      </div>
                      <div>
                        <span class="winBox">
                          <img class="winIcon"  @click="skipUserInfo(tab3PkList[0].uid2)" v-if="tab3PkList[0]&& tab3PkList[0].winId != 0 && tab3PkList[0].winId == tab3PkList[0].uid2" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                        </span>
                        <span class="pictureBox">
                          <img @click="skipUserInfo(tab3PkList[0].uid2)"  class="toukuang" v-if="tab3PkList[0] && tab3PkList[0].pictureFrameDetailMinUrl2" :src="tab3PkList[0].pictureFrameDetailMinUrl2" alt="">
                          <img @click="skipUserInfo(tab3PkList[0].uid2)" v-if="tab3PkList[0] && tab3PkList[0].headIcon2" :src="tab3PkList[0].headIcon2" alt="">
                          <template v-else>
                              <img v-if="!tab3PkList[0].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                              <img @click="skipUserInfo(tab3PkList[0].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                          </template>
                        </span>
                        <span class="userName" v-if="tab3PkList[0]">{{ tab3PkList[0].name2 }}</span>
                        <span v-else class="userName"></span>
                      </div>
                    </div>
                    <div class="userInfoCenter">
                      <span v-if="tab3PkList[0]" :class="tab3PkList[0].winId == tab3PkList[0].uid1 ? 'tab3ActTopleftLine' : ''"></span>
                      <span v-else></span>
                      <!-- <span>{{ countJson[0][0] }}</span> -->
                      <span></span>
                      <span v-if="tab3PkList[0]" :class="tab3PkList[0].winId == tab3PkList[0].uid2 ? 'tab3ActBottomleftLine' : ''"></span>
                      <span v-else></span>
                    </div>
                  </div>
                  <div class="userInfoLeftBottom">
                    <div class="userInfoLeft">
                      <div class="userInfoTop">
                        <span class="winBox">
                          <img class="winIcon" @click="skipUserInfo(tab3PkList[1].uid1)" v-if="tab3PkList[1] && tab3PkList[1].winId != 0 && tab3PkList[1].winId == tab3PkList[1].uid1" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                        </span>
                        <span class="pictureBox">
                          <img @click="skipUserInfo(tab3PkList[1].uid1)" class="toukuang" v-if="tab3PkList[1] && tab3PkList[1].pictureFrameDetailMinUrl1" :src="tab3PkList[1].pictureFrameDetailMinUrl1" alt="">
                          <img @click="skipUserInfo(tab3PkList[1].uid1)" v-if="tab3PkList[1] && tab3PkList[1].headIcon1" :src="tab3PkList[1].headIcon1" alt="">
                          <template v-else>
                            <img v-if="!tab3PkList[1].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                            <img @click="skipUserInfo(tab3PkList[1].uid1)" v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        </template>
                        </span>
                        <span class="userName" v-if="tab3PkList[1]">{{ tab3PkList[1].name1 }}</span>
                        <span v-else class="userName"></span>
                      </div>
                      <div>
                        <span class="winBox">
                          <img  class="winIcon" @click="skipUserInfo(tab3PkList[1].uid2)" v-if="tab3PkList[1] && tab3PkList[1].winId != 0 && tab3PkList[1].winId == tab3PkList[1].uid2" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                        </span>
                        <span class="pictureBox">
                          <img @click="skipUserInfo(tab3PkList[1].uid2)" class="toukuang" v-if="tab3PkList[1] && tab3PkList[1].pictureFrameDetailMinUrl2" :src="tab3PkList[1].pictureFrameDetailMinUrl2" alt="">
                          <img @click="skipUserInfo(tab3PkList[1].uid2)" v-if="tab3PkList[1] && tab3PkList[1].headIcon2" :src="tab3PkList[1].headIcon2" alt="">
                          <template v-else>
                              <img v-if="!tab3PkList[1].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                              <img @click="skipUserInfo(tab3PkList[1].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                          </template>
                        </span>
                        <span class="userName" v-if="tab3PkList[1]">{{ tab3PkList[1].name2 }}</span>
                        <span v-else class="userName"></span>
                      </div>
                    </div>
                    <div class="userInfoCenter">
                      <span v-if=" tab3PkList[1]" :class="tab3PkList[1].winId == tab3PkList[1].uid1 ? 'tab3ActTopleftLine' : ''"></span>
                      <span v-else></span>
                      <!-- <span>{{ countJson[0][1] }}</span> -->
                      <span></span>
                      <span v-if=" tab3PkList[1]" :class="tab3PkList[1].winId == tab3PkList[1].uid2 ? 'tab3ActBottomleftLine' : ''"></span>
                      <span></span>
                    </div>
                  </div>
                </div>
            </div>
            <!-- 中间获胜信息 -->
            <div class="lastCenterRank">
              <div class="centerTop">
                <span>{{$t("pkSemi")}} </span>
                <span v-if="tab3PkList[7]">
                  <img class="top1PicBg" src="../../assets/pkTournamentProV7/top1PicBg.png" alt="">
                  <!-- <img @click="skipUserInfo(tab3PkList[6].uid1)" class="toukuang top1Toukuang" v-if="tab3PkList[6] && tab3PkList[6].winId != 0 && tab3PkList[6].winId == tab3PkList[6].uid1 && tab3PkList[6].pictureFrameDetailMinUrl1" :src="tab3PkList[6].pictureFrameDetailMinUrl1" alt=""> -->
                  <!-- <img  @click="skipUserInfo(tab3PkList[6].uid2)" class="toukuang top1Toukuang" v-if="tab3PkList[6] && tab3PkList[6].winId != 0  && tab3PkList[6].winId == tab3PkList[6].uid2 && tab3PkList[6].pictureFrameDetailMinUrl2" :src="tab3PkList[6].pictureFrameDetailMinUrl2" alt=""> -->
                  <img @click="skipUserInfo(tab3PkList[7].uid1)" class="top1Picture top1Touxiang" v-if="tab3PkList[7] && tab3PkList[7].winId != 0 && tab3PkList[7].winId == tab3PkList[7].uid1 && tab3PkList[7].headIcon1" :src="tab3PkList[7].headIcon1" alt="">
                  <img @click="skipUserInfo(tab3PkList[7].uid2)" class="top1Picture top1Touxiang" v-else-if="tab3PkList[7] && tab3PkList[7].winId != 0  && tab3PkList[7].winId == tab3PkList[7].uid2 && tab3PkList[7].headIcon2" :src="tab3PkList[7].headIcon2" alt="">
                  <template v-else>
                    <img v-if="tab3PkList[7].winId != 0 && tab3PkList[7].winId == tab3PkList[7].uid1" class="top1Picture nullHeadImg" @click="skipUserInfo(tab3PkList[7].uid1)" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    <img v-if="tab3PkList[7].winId != 0 && tab3PkList[7].winId == tab3PkList[7].uid2" class="top1Picture nullHeadImg" @click="skipUserInfo(tab3PkList[7].uid2)" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    <img v-if="tab3PkList[7].winId == 0" class="top1Picture nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                  </template>
                  <span class="userName top1UserName" v-if="tab3PkList[7] && tab3PkList[7].winId != 0 && tab3PkList[7].winId == tab3PkList[7].uid1">{{ tab3PkList[7].name1 }}</span>
                  <span class="userName top1UserName" v-if="tab3PkList[7] && tab3PkList[7].winId != 0 && tab3PkList[7].winId == tab3PkList[7].uid2">{{ tab3PkList[7].name2 }}</span>
                </span>
                <span v-else>
                  <img class="top1PicBg" src="../../assets/pkTournamentProV7/top1PicBg.png" alt="">
                  <img class="top1Picture nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                </span>
                <span>{{$t("pkSemi")}} </span>
              </div>
              <div class="centerTopTwo">
                <div class="userInfoTop">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[4].uid1)" v-if="tab3PkList[4] && tab3PkList[4].winId != 0 && tab3PkList[4].winId == tab3PkList[4].uid1" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                  <img @click="skipUserInfo(tab3PkList[4].uid1)" class="toukuang" v-if="tab3PkList[4] && tab3PkList[4].pictureFrameDetailMinUrl1" :src="tab3PkList[4].pictureFrameDetailMinUrl1" alt="">
                    <img @click="skipUserInfo(tab3PkList[4].uid1)" v-if="tab3PkList[4] && tab3PkList[4].headIcon1" :src="tab3PkList[4].headIcon1" alt="">
                    <!-- <img v-else src="../../assets/pkTournamentProV7/pkNull.png" alt=""> -->
                    <template v-else>
                        <img v-if="!tab3PkList[4].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img v-else @click="skipUserInfo(tab3PkList[4].uid1)" class="nullHeadImg"  src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[4]">{{  tab3PkList[4].name1  }}</span>
                  <span v-else class="userName"></span>
                </div>
                <div class="centerText">{{$t("pkFinal")}}</div>
                <div class="userInfoTop userInfoTopRight">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[5].uid1)" v-if="tab3PkList[5] && tab3PkList[5].winId != 0 && tab3PkList[5].winId == tab3PkList[5].uid1" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                    <img @click="skipUserInfo(tab3PkList[5].uid1)" class="toukuang" v-if="tab3PkList[5] && tab3PkList[5].pictureFrameDetailMinUrl1" :src="tab3PkList[5].pictureFrameDetailMinUrl1" alt="">
                    <img @click="skipUserInfo(tab3PkList[5].uid1)" v-if="tab3PkList[5] && tab3PkList[5].headIcon1" :src="tab3PkList[5].headIcon1" alt="">
                    <template v-else>
                        <img v-if="!tab3PkList[5].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img v-else @click="skipUserInfo(tab3PkList[5].uid1)" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[5]">{{ tab3PkList[5].name1 }}</span>
                  <span v-else class="userName"></span>
                </div>
              </div>
              <div class="centerTopThree">
                <div class="userInfoTop">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[7].uid1)" v-if="tab3PkList[7] && tab3PkList[7].winId != 0 && tab3PkList[7].winId != 0 && tab3PkList[7].winId == tab3PkList[7].uid1" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                    <img @click="skipUserInfo(tab3PkList[7].uid1)" class="toukuang" v-if="tab3PkList[7] && tab3PkList[7].pictureFrameDetailMinUrl1" :src="tab3PkList[7].pictureFrameDetailMinUrl1" alt="">
                    <img @click="skipUserInfo(tab3PkList[7].uid1)" v-if="tab3PkList[7] && tab3PkList[7].headIcon1" :src="tab3PkList[7].headIcon1" alt="">
                    <template v-else>
                        <img v-if="!tab3PkList[7].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img @click="skipUserInfo(tab3PkList[7].uid1)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[7]">{{ tab3PkList[7].name1 }}</span>
                  <span v-else class="userName"></span>
                </div>
                <div class="userInfoTop">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[7].uid2)" v-if="tab3PkList[7] && tab3PkList[7].winId != 0 && tab3PkList[7].winId != 0 && tab3PkList[7].winId == tab3PkList[7].uid2" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                    <img @click="skipUserInfo(tab3PkList[7].uid2)" class="toukuang" v-if="tab3PkList[7] && tab3PkList[7].pictureFrameDetailMinUrl2" :src="tab3PkList[7].pictureFrameDetailMinUrl2" alt="">
                    <img @click="skipUserInfo(tab3PkList[7].uid2)" v-if="tab3PkList[7] && tab3PkList[7].headIcon2" :src="tab3PkList[7].headIcon2" alt="">
                    <template v-else>
                        <img v-if="!tab3PkList[7].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img @click="skipUserInfo(tab3PkList[7].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[7]">{{ tab3PkList[7].name2 }}</span>
                  <span v-else class="userName"></span>
                </div>
              </div>
              <div class="centerTopThree centerTopFour">
                <div class="userInfoTop">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[6].uid1)" v-if="tab3PkList[6] && tab3PkList[6].winId != 0 && tab3PkList[6].winId != 0 && tab3PkList[6].winId == tab3PkList[6].uid1" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                    <img @click="skipUserInfo(tab3PkList[6].uid1)" class="toukuang" v-if="tab3PkList[6] && tab3PkList[6].pictureFrameDetailMinUrl1" :src="tab3PkList[6].pictureFrameDetailMinUrl1" alt="">
                    <img @click="skipUserInfo(tab3PkList[6].uid1)" v-if="tab3PkList[6] && tab3PkList[6].headIcon1" :src="tab3PkList[6].headIcon1" alt="">
                    <template v-else>
                        <img v-if="!tab3PkList[6].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img @click="skipUserInfo(tab3PkList[6].uid1)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[6]">{{ tab3PkList[6].name1 }}</span>
                  <span v-else class="userName"></span>
                </div>
                <div class="userInfoTop">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[6].uid2)" v-if="tab3PkList[6] && tab3PkList[6].winId != 0 && tab3PkList[6].winId != 0 &&  tab3PkList[6].winId == tab3PkList[6].uid2" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                    <img @click="skipUserInfo(tab3PkList[6].uid2)" class="toukuang" v-if="tab3PkList[6] && tab3PkList[6].pictureFrameDetailMinUrl2" :src="tab3PkList[6].pictureFrameDetailMinUrl2" alt="">
                    <img @click="skipUserInfo(tab3PkList[6].uid2)" v-if="tab3PkList[6] && tab3PkList[6].headIcon2" :src="tab3PkList[6].headIcon2" alt="">
                    <template v-else>
                        <img v-if="!tab3PkList[6].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img @click="skipUserInfo(tab3PkList[6].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[6]">{{ tab3PkList[6].name2 }}</span>
                  <span v-else class="userName"></span>
                </div>
              </div>
              <p class="place3rd">{{ $t('pk3rd') }}</p>
              <div class="centerTopTwo centerTopFive">
                <div class="userInfoTop">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[4].uid2)" v-if="tab3PkList[4] && tab3PkList[4].winId != 0 && tab3PkList[4].winId == tab3PkList[4].uid2" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                    <img @click="skipUserInfo(tab3PkList[4].uid2)" class="toukuang" v-if="tab3PkList[4] && tab3PkList[4].pictureFrameDetailMinUrl2" :src="tab3PkList[4].pictureFrameDetailMinUrl2" alt="">
                    <img @click="skipUserInfo(tab3PkList[4].uid2)" v-if="tab3PkList[4] && tab3PkList[4].headIcon2" :src="tab3PkList[4].headIcon2" alt="">
                    <template v-else>
                        <img v-if="!tab3PkList[4].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img @click="skipUserInfo(tab3PkList[4].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[4]">{{ tab3PkList[4].name2 }}</span>
                  <span v-else class="userName"></span>
                </div>
                <div class="userInfoTop userInfoTopRight">
                  <span class="winBox">
                    <img class="winIcon" @click="skipUserInfo(tab3PkList[5].uid2)" v-if="tab3PkList[5] && tab3PkList[5].winId != 0 && tab3PkList[5].winId == tab3PkList[5].uid2" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                  </span>
                  <span class="pictureBox">
                    <img @click="skipUserInfo(tab3PkList[5].uid2)" class="toukuang" v-if="tab3PkList[5] && tab3PkList[5].pictureFrameDetailMinUrl2" :src="tab3PkList[5].pictureFrameDetailMinUrl2" alt="">
                    <img @click="skipUserInfo(tab3PkList[5].uid2)" v-if="tab3PkList[5] && tab3PkList[5].headIcon2" :src="tab3PkList[5].headIcon2" alt="">
                    <template v-else>
                        <img v-if="!tab3PkList[5].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img @click="skipUserInfo(tab3PkList[5].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                  </span>
                  <span class="userName" v-if="tab3PkList[5]">{{ tab3PkList[5].name2 }}</span>
                  <span v-else class="userName"></span>
                </div>
              </div>
              <div class="centerTopSix">
                <span>
                    <!-- <img @click="skipUserInfo(tab3PkList[7].uid2)" class="toukuang" v-if="tab3PkList[7]  && tab3PkList[7].winId == tab3PkList[7].uid2 && tab3PkList[7].pictureFrameDetailMinUrl2" :src="tab3PkList[7].pictureFrameDetailMinUrl2" alt=""> -->
                    <!-- <img @click="skipUserInfo(tab3PkList[7].uid1)" class="toukuang" v-if="tab3PkList[7]  && tab3PkList[7].winId == tab3PkList[7].uid1 && tab3PkList[7].pictureFrameDetailMinUrl1" :src="tab3PkList[7].pictureFrameDetailMinUrl1" alt=""> -->
                  <img @click="skipUserInfo(tab3PkList[6].uid2)" v-if="tab3PkList[6] && tab3PkList[6].winId == tab3PkList[6].uid2 && tab3PkList[6].headIcon2" :src="tab3PkList[6].headIcon2" alt="">
                  <img @click="skipUserInfo(tab3PkList[6].uid1)" v-else-if="tab3PkList[6] && tab3PkList[6].winId == tab3PkList[6].uid1 && tab3PkList[6].headIcon1" :src="tab3PkList[6].headIcon1" alt="">
                  <template v-else>
                        <img v-if="!tab3PkList[6]" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img v-else-if="tab3PkList[6].winId == tab3PkList[6].uid2" @click="skipUserInfo(tab3PkList[6].uid2)" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img v-else-if="tab3PkList[6].winId == tab3PkList[6].uid1" @click="skipUserInfo(tab3PkList[6].uid1)" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        <img v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                    </template>
                    <span class="userName top1UserName" v-if="tab3PkList[6] && tab3PkList[6].winId != 0 && tab3PkList[6].winId == tab3PkList[6].uid1">{{ tab3PkList[6].name1 }}</span>
                  <span class="userName top1UserName" v-if="tab3PkList[6] && tab3PkList[6].winId != 0 && tab3PkList[6].winId == tab3PkList[6].uid2">{{ tab3PkList[6].name2 }}</span>
                </span>
              </div>
            </div>
            <!-- 右侧用户信息 3,4名 -->
            <div class="lastRightRank lastLeftRank">
                <header>{{$t("pkQuater")}}</header>
              <div class="userInfoLeftBox">
                <div class="userInfoLeftTop">
                  <div class="userInfoCenter">
                    <span :class="tab3PkList[2] && tab3PkList[2].winId == tab3PkList[2].uid1 ? 'top3ActRightTopLine' : ''"></span>
                    <span></span>
                    <span :class="tab3PkList[2] && tab3PkList[2].winId == tab3PkList[2].uid2 ? 'top3ActRightBottomLine' : ''"></span>
                  </div>
                  <div class="userInfoLeft">
                    <div class="userInfoTop">
                      <span class="winBox">
                        <img class="winIcon" @click="skipUserInfo(tab3PkList[2].uid1)" v-if="tab3PkList[2] && tab3PkList[2].winId != 0 && tab3PkList[2].winId == tab3PkList[2].uid1" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox">
                       <img @click="skipUserInfo(tab3PkList[2].uid1)" class="toukuang" v-if="tab3PkList[2] && tab3PkList[2].pictureFrameDetailMinUrl1" :src="tab3PkList[1].pictureFrameDetailMinUrl1" alt="">
                        <img @click="skipUserInfo(tab3PkList[2].uid1)" v-if="tab3PkList[2] && tab3PkList[2].headIcon1" :src="tab3PkList[2].headIcon1" alt="">
                        <template v-else>
                            <img v-if="!tab3PkList[2].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                            <img @click="skipUserInfo(tab3PkList[2].uid1)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        </template>
                      </span>
                      <span class="userName" v-if="tab3PkList[2]">{{ tab3PkList[2].name1 }}</span>
                      <span v-else class="userName"></span>
                    </div>
                    <div>
                      <span class="winBox">
                        <img class="winIcon"  @click="skipUserInfo(tab3PkList[2].uid2)" v-if="tab3PkList[2] && tab3PkList[2].winId != 0 && tab3PkList[2].winId == tab3PkList[2].uid2" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox">
                        <img @click="skipUserInfo(tab3PkList[2].uid2)" class="toukuang" v-if="tab3PkList[2] && tab3PkList[2].pictureFrameDetailMinUrl2" :src="tab3PkList[2].pictureFrameDetailMinUrl2" alt="">
                        <img @click="skipUserInfo(tab3PkList[2].uid2)" v-if="tab3PkList[2] && tab3PkList[2].headIcon2" :src="tab3PkList[2].headIcon2" alt="">
                        <template v-else>
                            <img v-if="!tab3PkList[2].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                            <img @click="skipUserInfo(tab3PkList[2].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        </template>
                      </span>
                      <span class="userName" v-if="tab3PkList[2]">{{ tab3PkList[2].name2 }}</span>
                      <span v-else class="userName"></span>
                    </div>
                  </div>
                </div>
                <div class="userInfoLeftBottom  userInfoLeftBottomRight">
                  <div class="userInfoCenter">
                    <span :class="tab3PkList[3] && tab3PkList[3].winId == tab3PkList[3].uid1 ? 'top3ActRightTopLine' : ''"></span>
                    <span></span>
                    <span :class="tab3PkList[3] && tab3PkList[3].winId == tab3PkList[3].uid2 ? 'top3ActRightBottomLine' : ''"></span>
                  </div>
                  <div class="userInfoLeft">
                    <div class="userInfoTop">
                      <span class="winBox">
                        <img @click="skipUserInfo(tab3PkList[3].uid1)" v-if="tab3PkList[3] && tab3PkList[3].winId != 0 && tab3PkList[3].winId == tab3PkList[3].uid1" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox">
                        <img @click="skipUserInfo(tab3PkList[3].uid1)" class="toukuang" v-if="tab3PkList[3] && tab3PkList[3].pictureFrameDetailMinUrl1" :src="tab3PkList[3].pictureFrameDetailMinUrl1" alt="">
                        <img @click="skipUserInfo(tab3PkList[3].uid1)" v-if="tab3PkList[3] && tab3PkList[3].headIcon1" :src="tab3PkList[3].headIcon1" alt="">
                        <template v-else>
                            <img v-if="!tab3PkList[3].uid1" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                            <img @click="skipUserInfo(tab3PkList[3].uid1)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                        </template>
                      </span>
                      <span class="userName" v-if="tab3PkList[3]">{{ tab3PkList[3].name1 }}</span>
                      <span v-else class="userName"></span>
                    </div>
                    <div>
                      <span class="winBox">
                        <img @click="skipUserInfo(tab3PkList[3].uid2)" v-if="tab3PkList[3] && tab3PkList[3].winId != 0 && tab3PkList[3].winId == tab3PkList[3].uid2" class="winIcon" src="../../assets/pkTournamentProV7/winIcon.png" alt="">
                      </span>
                      <span class="pictureBox">
                         <img @click="skipUserInfo(tab3PkList[3].uid2)" class="toukuang" v-if="tab3PkList[3] && tab3PkList[3].pictureFrameDetailMinUrl2" :src="tab3PkList[3].pictureFrameDetailMinUrl2" alt="">
                        <img @click="skipUserInfo(tab3PkList[3].uid2)" v-if="tab3PkList[3] && tab3PkList[3].headIcon2" :src="tab3PkList[3].headIcon2" alt="">
                        <template v-else>
                          <img v-if="!tab3PkList[3].uid2" class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                          <img @click="skipUserInfo(tab3PkList[3].uid2)" class="nullHeadImg" v-else src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </template>
                      </span>
                      <span class="userName" v-if="tab3PkList[3]">{{ tab3PkList[3].name2 }}</span>
                      <span v-else class="userName"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- 预 告 -->
        <div class="scheduleBox" v-if="tabOneFlag == 2" >
          <img class="longBg" :src="tabOne2Bg" alt="">
          <ul class="scheduleList" v-if="pkAdvanceList.length > 0">
            <li class="scheduleItem" v-for="(item,index) in pkAdvanceList" :key="index">
                <header class="scheduleTime">{{convertToEastThreeZone(item.startTime)}}</header>
                <div class="scheduleUserInfoBox">
                  <div class="scheduleUserInfoLeft">
                     <div class="picBox" @click="skipUserInfo(item.uid1)">
                      <img class="pkWinIcon" v-if="item.winId == item.uid1" src="../../assets/pkTournamentProV7/pkWinIcon.png" alt="">
                      <img class="pkToukuang" v-if="item.pictureFrameDetailMinUrl1" :src="item.pictureFrameDetailMinUrl1" alt="">
                      <span class="userPictureBox">
                        <img v-if="item.headIcon1" :src="item.headIcon1" alt="">
                        <img v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                     </div>
                     <p class="userNameBox">{{item.name1}}</p>
                     <div class="pkIconBox">
                      <span v-if="item.vipLevel1 > 0" :class="['vip', 'vip' + item.vipLevel1]"></span>
                      <span :class="['sub', 'sub' + langs]" v-if="item.vip1 == 1"></span>
                      <span :class="['psub', 'psub' + langs]" v-if="item.svip1 == 1"></span>
                      <span v-if="item.glamourLevel1 > 0" :class="['charm',  countCharmClass(item.glamourLevel1)]">{{ item.glamourLevel1 }}</span>
                     </div>
                  </div>
                  <div class="scheduleUserInfoLeft">
                     <div class="picBox" @click="skipUserInfo(item.uid2)">
                      <img class="pkWinIcon" v-if="item.winId == item.uid2" src="../../assets/pkTournamentProV7/pkWinIcon.png" alt="">
                      <img class="pkToukuang" v-if="item.pictureFrameDetailMinUrl2" :src="item.pictureFrameDetailMinUrl2" alt="">
                      <span class="userPictureBox">
                        <img  v-if="item.headIcon2" :src="item.headIcon2" alt="">
                        <img v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                      </span>
                     </div>
                     <p class="userNameBox">{{item.name2}}</p>
                     <div class="pkIconBox">
                      <span v-if="item.vipLevel2 > 0" :class="['vip', 'vip' + item.vipLevel2]"></span>
                      <span :class="['sub', 'sub' + langs]" v-if="item.vip2 == 1"></span>
                      <span :class="['psub', 'psub' + langs]" v-if="item.svip2 == 1"></span>
                      <span v-if="item.glamourLevel2 > 0" :class="['charm',  countCharmClass(item.glamourLevel2)]">{{ item.glamourLevel2 }}</span>
                     </div>
                  </div>
                </div>
            </li>
          </ul>
        </div>
        <!-- 送礼榜 -->
        <div class="supporterBox" v-if="tabOneFlag == 3">
          <div v-if="pkGiftList.length > 0">
            <ul class="rankTab1_3">
              <li>
                <div class="pictureBox" @click="skipUserInfo(pkGiftList[1].uid)">
                  <img class="pkToukuang" src="../../assets/pkTournamentProV7/userTop2.png" alt="">
                  <img v-if="pkGiftList[1] && pkGiftList[1].headIcon" :src="pkGiftList[1].headIcon" alt="">
                  <img v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                </div>
                  <p class="userNameBox" v-if="pkGiftList[1]">{{ pkGiftList[1].name}}</p>
                <div class="pkIconBox">
                  <span :class="['vip', 'vip' + pkGiftList[1].vipLv] " v-if="pkGiftList[1] && pkGiftList[1].vipLv > 0"></span>
                  <span :class="['psub', 'psub' + langs]" v-if="pkGiftList[1] && pkGiftList[1].svip == 1"></span>
                  <span :class="['sub', 'sub' + langs]" v-else-if="pkGiftList[1] && pkGiftList[1].vip == 1"></span>
                  <span v-if="pkGiftList[1] && pkGiftList[1].wealthLv > 0" :class="['wealth', countClass(pkGiftList[1].wealthLv)]">
                        <span>{{ pkGiftList[1].wealthLv }}</span>
                      </span>
                </div>
                <p class="zuanshiBox" v-if="pkGiftList[1]">{{pkGiftList[1].score | formatNumber}}</p>
              </li>
              <li>
                <div class="pictureBox" @click="skipUserInfo(pkGiftList[0].uid)">
                  <img class="pkToukuang" src="../../assets/pkTournamentProV7/userTop1.png" alt="">
                  <img  v-if="pkGiftList[0] && pkGiftList[0].headIcon" :src="pkGiftList[0].headIcon" alt="">
                  <img v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                </div>
                  <p class="userNameBox" v-if="pkGiftList[0]">{{ pkGiftList[0].name}}</p>
                <div class="pkIconBox">
                  <span :class="['vip', 'vip' + pkGiftList[0].vipLv] " v-if="pkGiftList[0] && pkGiftList[0].vipLv > 0"></span>
                  <span :class="['psub', 'psub' + langs]" v-if="pkGiftList[0] && pkGiftList[0].svip == 1"></span>
                  <span :class="['sub', 'sub' + langs]" v-else-if="pkGiftList[0] && pkGiftList[0].vip == 1"></span>
                  <span v-if="pkGiftList[0] && pkGiftList[0].wealthLv > 0" :class="['wealth', countClass(pkGiftList[0].wealthLv)]">
                        <span>{{ pkGiftList[0].wealthLv }}</span>
                      </span>
                </div>
                <p class="zuanshiBox" v-if="pkGiftList[0]">{{pkGiftList[0].score | formatNumber}}</p>
              </li>
              <li>
                <div class="pictureBox" @click="skipUserInfo(pkGiftList[2].uid)">
                  <img class="pkToukuang" src="../../assets/pkTournamentProV7/userTop3.png" alt="">
                  <img  v-if="pkGiftList[2] && pkGiftList[2].headIcon" :src="pkGiftList[2].headIcon" alt="">
                  <img v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                </div>
                  <p class="userNameBox" v-if="pkGiftList[2]">{{ pkGiftList[2].name}}</p>
                <div class="pkIconBox">
                  <span :class="['vip', 'vip' + pkGiftList[2].vipLv] " v-if="pkGiftList[2] && pkGiftList[2].vipLv > 0"></span>
                  <span :class="['psub', 'psub' + langs]" v-if="pkGiftList[2] && pkGiftList[2].svip == 1"></span>
                  <span :class="['sub', 'sub' + langs]" v-else-if="pkGiftList[2] && pkGiftList[2].vip == 1"></span>
                  <span v-if="pkGiftList[2] && pkGiftList[2].wealthLv > 0" :class="['wealth', countClass(pkGiftList[2].wealthLv)]">
                        <span>{{ pkGiftList[2].wealthLv }}</span>
                      </span>
                </div>
                <p class="zuanshiBox" v-if="pkGiftList[2]">{{pkGiftList[2].score | formatNumber}}</p>
              </li>
            </ul>
            <ul class="rankTab4_20">
              <template v-for="(item,index) in pkGiftList">
                <li :key="index" v-if="index > 2"> 
                  <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                  <div class="userPicture" @click="skipUserInfo(item.uid)">
                    <img class="toukuang" v-if="item.frameMinUrl" :src="item.frameMinUrl" alt="">
                    <img v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else class="nullHeadImg" src="../../assets/pkTournamentProV7/pkNull.png" alt="">
                  </div>
                  <div class="nameIconBox">
                    <p class="rankingUserName">{{ item.name }}</p>
                    <div class="iconsBox">
                      <span :class="['vip', 'vip' + item.vipLv] " v-if="item.vipLv > 0"></span>
                      <span :class="['sub', 'sub' + langs]" v-if="item.vip == 1"></span>
                      <span :class="['psub', 'psub' + langs]" v-if="item.svip == 1"></span>
                      <span v-if="item.wealthLv > 0" :class="['wealth', countClass(item.wealthLv)]">
                            <span>{{ item.wealthLv }}</span>
                          </span>
                    </div>
                  </div>
                  <p class="zuanshiBox">{{item.score | formatNumber}}</p>
                </li>
              </template>
            </ul>
          </div>
          <div v-else class="pkListNull"></div>
        </div>
     </div>
     <!-- 投票弹窗 -->
     <div class="votePopBox" v-if="votePop == 1">
      <span class="closeBtn" @click="changeVotePop(0)"></span>
      <div class="votePop">
        <div class="voteContentBox">
          <div class="noteBox">
            <p>{{$t('pk_vote_note')}}</p>
            <p>{{ $t('pk_vote_rule1') }} </p>
            <p>{{ $t('pk_vote_rule2') }} </p>
          </div>
          <div class="voteDownTime">
            <div>
              <!-- 天 -->
              <div class="numBox">
                <span>{{days[0]}}</span>
                <span>{{days[1]}}</span>
              </div>
              <div>{{ $t('valentine_time_days') }}</div>
            </div>
            <!-- 时 -->
            <div>
              <div class="numBox">
                <span>{{hours[0]}}</span>
                <span>{{hours[1]}}</span>
              </div>
              <div>{{ $t('valentine_time_hours') }}</div>
            </div>
            <!-- 分 -->
            <div>
              <div class="numBox">
                <span>{{minutes[0]}}</span>
                <span>{{minutes[1]}}</span>
              </div>
              <div>{{ $t('valentine_time_minutes') }}</div>
            </div>
            <!-- 秒 -->
            <div>
              <div class="numBox">
                <span>{{seconds[0]}}</span>
                <span>{{seconds[1]}}</span>
              </div>
              <div>{{ $t('valentine_time_seconds') }}</div>
            </div>
          </div>
          <div class="voteUserBox">
            <div class="voteUser" v-for="(item,index) in voteInfo.votes" :key="index">
              <div class="userIcon">
                <img :src="item.headIcon" alt="">
              </div>
              <p class="voteUserName">{{item.name}}</p>
              <div class="voteNum">{{item.vote}}</div>
              <div class="voteBtn" @click="submitVote(item)">{{ $t('pk_vote_buttom2') }}</div>
            </div>
          </div>
        </div>
      </div>
     </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
export default {
  name: "pkTournamentPro",
  data() {
    return{
      tabTwoFlag: 1,
      tabOneFlag: 1,
    // pkId
      cfgId:0,
      // pk数据
      tabOne2Bg:'',
      tab1PkList:[],
      tab2PkList:[],
      tab3PkList:[],
      pkGiftList:[],
      // pk预告数据
      pkAdvanceList:[],
      // 计数数据
      countJson:{
        0:['01','02'],
        1:['03','04'],
        2:['05','06'],
        3:['07','08'],
        4:['09','10'],
        5:['11','12'],
        6:['13','14'],
        7:['15','16'],
        8:['17','18'],
        9:['19','20']
      },
      langs:'en',
      // 投票倒计时
      days:[0,0],
      hours:[0,0],
      minutes:[0,0],
      seconds:[0,0],
      // 是否展示投票 1显示 0不显示
      voteView:0,
      votePop:0,
      voteInfo:{},
      // 投票按钮防止连点
      voteType:1
    }
  },
  created() { 
    this.cfgId = this.$route.query.cfgId;
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()

    // this.getUserInfo() 

  },

  filters:{
    formatNumber(num) {
      if (num > 1000000) {
          return Math.trunc((num / 1000000) * 10) / 10 + 'M';
      } else if (num >= 1000) {
          return Math.trunc((num / 1000) * 10) / 10 + 'K';
      } else {
          return num.toString ();
      }
    },
    topFilter(top){
      if(top > 99){
        return '99+';
      }else if(top == 0){
        return '--'
      }else{
        return top > 9 ? top : '0' + top
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    },
  },
  methods: { 
    // 展示隐藏投票弹窗
    changeVotePop(type){
      this.votePop = type;
      if(type == 1){
        // 禁止页面滚动
        this.stopScroll();
        this.getVoteInfo();
      }else{
        this.canScroll();
        clearInterval(this.timer);
      }
    },
    // 获取投票相关信息
    getVoteInfo(){
      let paramObjs = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ranking/pk/voteview?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&cfgId=" +
          this.cfgId,
        data: "",
        header: this.headers,
      };
      this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then(data => {
       if (data.code == 200) {
          this.voteInfo = data.data;
          this.finalsDownTime(this.voteInfo.expireTime);
        }else {
          Toast(data.message || this.$t('failed'))
        }
      }).catch(error => {
        Toast(error.message);
      });
    },
    // 投票按钮
    submitVote(userInfo){
      if(this.voteType == 1){
        this.voteType = 0;
        let paramObjs = {
          option: "get",
          host:
            this.$serviceIpJava +
            "api/activity/ranking/pk/vote?token=" +
            this.token +
            "&uid=" +
            this.uid+
            "&cfgId=" +
            this.cfgId +
            "&targetId=" +
            userInfo.uid,
          data: "",
          header: this.headers,
        };
        this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then(data => {
          this.voteType = 1;
        if (data.code == 200) {
            userInfo.vote = data.data;
            Toast(this.$t('pk_vote_success'))
          }else {
            Toast(data.message || this.$t('failed'))
          }
        }).catch(error => {
          Toast(error.message);
        });
      }
      
    },
    // 倒计时设置
    finalsDownTime(endTime) {
      this.timer = setInterval( ()=> {
        const now = new Date().getTime();
        const distance = endTime - now;
        if (distance < 0) {
          clearInterval(this.timer);
          return;
        }
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24)));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));

        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }, 1000);
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 跳转资料页  需要uid
    skipUserInfo(uid) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(uid));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(uid));
      }
    },
    // 转换东三区时间
    convertToEastThreeZone(timestamp) {
      let w3Time = 0;
          // 将时间戳转换为 Date 对象
      const date = new Date(timestamp);
      // 获取本地时间
      const localTime = date.toLocaleString();
      // 输出本地时间
      console.log(localTime); // 输出客户端所在时区的时间
      // 使用 Intl.DateTimeFormat 进行更详细的格式化
      w3Time = new Intl.DateTimeFormat(
        navigator.language || 'en-US',
        {
          hour12: false, // 使用 24 小时制
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        }
      ).format(date);
      return w3Time.slice(0,5);
    },
    // 点击第一组tab
    changeTabOneFlag(type){
      this.tabOneFlag = type;
      if(type == 1){
        this.changeTabTwoFlag(1)
      }
      if(type == 3){
        this.getPkGiftList()
      }
    },
    // 点击进度tab
    changeTabTwoFlag(type){
      this.tabTwoFlag = type;
    },
    // 跳转资料页  需要uid
    skipUserInfo(uid) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(uid));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(uid));
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang,
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
      // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt5TWpneiIsInh5eiI6IlJUQTNORFZCTkRBdE1qUkdRaTAwT1RKQ0xVRXhNak10T1VFMFJEUkdNek14UVVJdyIsImV4cCI6MTk4NDAyNTYzMH0.KrZfZ2yE5MHJ28PRPqUrVETKmHnJSZeeV-PYJYcxIoU';
      // this.uid = '792283'; 
      // this.headers = {
      //     fid: '1001',
      //     os: 1,
      //     lang: 'zh-CN',
      //     version: '3.0',
      //     deviceid: 'E0745A40-24FB-492B-A123-9A4D4F331AB0',
      // }
      // let langs = 'es'
      // this.langs = 'es'
      // let lang = 'zh_CN'
      if (langs == "es") {
          this.$i18n.locale = "es";
          document.title = "Torneo PK Pro"
      } else {
          this.$i18n.locale = "en";
          document.title = "PK Tourament Pro"
      }
      this.getPkListInfo()
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      
    },
    // 获取pk数据
    getPkListInfo(){
      let paramObjs = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ranking/pk/cfg?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&cfgId=" +
          this.cfgId,
        data: "",
        header: this.headers,
      };
      this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then((data) => {
        if (data.code == 200) {
          this.tabOne2Bg = data.data.preIcon;
          this.voteView = data.data.voteView;
          this.isAdvance(data.data)
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    // 获取pk送礼数据
    getPkGiftList(){
      let paramObjs = {
        option: "get",
        host:
          this.$serviceIpJava +
          "/api/activity/ranking/pk/getList?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&cfgId=" +
          this.cfgId,
        data: "",
        header: this.headers,
      };
      this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then((data) => {
        if (data.code == 200) {
          this.pkGiftList = data.data;
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    // 区分是否预告
    isAdvance(data){
      // 存储所有pk对
      let pkInfoList = [];
      data.pkTeams.map((item)=> pkInfoList = [...pkInfoList,...item.pks]);
      pkInfoList.map((item)=>{
        if(item.type == 1){
          this.pkAdvanceList.push(item)
        }
      })
      this.tab1PkList = this.twoGrouping(data.pkTeams[0].pks)
      this.tab2PkList = this.twoGrouping(data.pkTeams[1].pks)
      this.tab3PkList = data.pkTeams[2].pks
      
      // 如果第一组都有胜负 展示第二组 如果第二组都有胜负展示第三组
      let tab1Show = data.pkTeams[0].pks.every((item)=>{
        return item.winId != 0
      })
      let tab2Show = data.pkTeams[1].pks.every((item)=>{
        return item.winId != 0
      })
      let showTabType = tab2Show ? 3 : (tab1Show ? 2 : 0);
      showTabType && this.changeTabTwoFlag(showTabType);
      
    },
    twoGrouping(data){
      // 存储非预告pk对
      let pkInfoLeftRight = [];
       // 将相邻pk对调整为两两配对
      for (let i = 0; i < data.length; i += 2) {
        if (i + 1 < data.length ) {
          pkInfoLeftRight.push({
            leftUserInfo: data[i],
            rightUserInfo: data[i + 1],
          });
        } else {
          // 如果最后一个元素没有配对，则单独作为一个元素
          pkInfoLeftRight.push({
            leftUserInfo: data[i]
          });
        }
      }
      return pkInfoLeftRight
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },

    
  },
};
</script>

<style scoped>
@import './index.css';

@import '../../assets/gradeIcon/gradeIcon.css';
</style>
